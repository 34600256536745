<template>
  <list-template
      :current-page="page"
      :loading="loading"
      :searchConfig="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      has-add
      @onAdd="$router.push('./add')"
      @onChangePage="changeCurrentPage"
      @onHandle="tableHandle"
      @onReset="onReset"
      @onSearch="onSearch"
  ></list-template>
</template>

<script>
//引入ajax接口管理层的接口函数

import {delBuildings} from '@/api/building'
import {mapState} from 'vuex'

export default {
  _config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
  computed: {
    ...mapState(['page'])
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    //
    tableHandle(row, handle) {
      const {type} = handle
      if (type === 'delete') {
        this.$confirm('删除后会影响其他功能使用，是否删除', {
          confirmButtonText: '删除',
          cancelButtonText: '点错了',
          type: 'warning'
        })
            .then(async () => {
              let res = await delBuildings(row.id)
              await this.getData()
              this.$message({
                type: 'success',
                message: '删除成功'
              })
            })
            .catch(async () => {
            })
      } else if (type === 'edit') {
        this.$router.push({path: './edit', query: {id: row.id}})
      }
    },
    async getData() {
      let page = this.page
      if (this.search === null) this.search = {}
      this.loading = true;
      this.$_axios2.get('api/dormitory/houses/get-list', {params: {page: page, ...this.search}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(() => this.loading = false)
    },
    onSearch(val) {
      this.search = val
      this.getData()
    },

    //  点击新增跳到添加页面。
    linkAdd() {
      this.$router.push('./add')
    },
    //  点击编辑跳到编辑页面并把row这一行的数据作为参数传递。
    handleEdit(row) {
      this.$router.push({path: './edit', query: {id: row.id}})
    },

    // 删除数据
    async handleDelete(row) {
      this.$confirm('删除后会影响其他功能使用，是否删除', {
        confirmButtonText: '删除',
        cancelButtonText: '点错了',
        type: 'warning'
      }).then(async () => {
        let res = await delBuildings(row.id)
        await this.getData()
        this.$message({
          type: 'success',
          message: '删除成功'
        })
      })

    },

    handleCurrentChange(val) {
      this.$store.commit('setPage', val)
      this.getData()
    },
    onReset() {
      this.search = null
      this.searchConfig[1].options = []
    }
  },

  data() {
    return {
      loading: true,
      search: null,
      tableData: [
        {
          name: '',
          usage: '',
          floor_min: '',
          floor_max: '',
          school_name: ''
        }
      ],
      total: 0,
      tableConfig: [
        {
          prop: 'dimicile_buliding_name',
          label: '楼宇名称'
        },
        {
          prop: 'school_name',
          label: '所属校区'
        },
        {
          prop: 'usage',
          label: '楼宇用途'
        },
        {
          prop: 'floor_min',
          label: '最低楼层'
        },
        {
          prop: 'floor_max',
          label: '最高楼层'
        },
        {
          handle: true,
          width: 120,
          label: '操作',
          buttons: [{type: "delete", text: "删除"}]
        }
      ],
      searchConfig: [
        {
          prop: 'school_id',
          tag: 'select',
          placeholder: '筛选校区',
          options: [],
          label: 'school_name',
          value: 'id',
          change: (val, search) => {
            if (search.name) search.name = ''
            this.$_axios2.get('api/dormitory/houses/linkage-school?school_id=' + val).then(res => {
              this.searchConfig[1].options = res.data.data
            })
          }
        },
        {
          prop: 'name',
          tag: 'select',
          placeholder: '筛选楼宇',
          options: [],
          label: 'dimicile_buliding_name',
          value: 'dimicile_buliding_name'
        }
      ],
    }
  },
  activated() {
    this.getData()
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.$_axios2.get('api/common/base-school?usage=2').then(res => {
      this.searchConfig[0].options = res.data.data.school
    })
  }
}
</script>

<style lang="scss" scoped></style>
